<template>
    <div class="portfolio-piece">
        <div class="scroll-header">
            <div class="inf-scroll">
                <div>
                    <h1>Etaerio <span>Spirits Company</span></h1>
                    <h1>Etaerio <span>Spirits Company</span></h1>
                </div>
            </div>
        </div>
        <h2>Brand Design | Packaging | 3D Modelling</h2>

        <div class="portfolio-cont" :style="{'background-image': `url(${require('../assets/images/portfolio/et-backdrop.png')})`}">

            <div class="p-content-row-2">
                <div>
                    <figure class="medium-img">
                        <img alt="etaerio brand identity" src="../assets/images/portfolio/et-brand.webp">
                    </figure>
                </div>
                <div>
                    <p>The Etaerio Spirits Company was unique for using cleaner ingredients for their array of specialty liqueurs, while representing a higher-end lifestyle. For the brand, attention to a 
                        clean, minimal style that also communicated a harmony with nature was extremely important. <br><br>It was also clear that these unique products demanded a unique bottle, which was 
                        designed to imitate the geometric sphere icon from which the secondary elements also derived. The product naming convention was also chosen to evoke the significance of the ingredient - 
                        each liqueur is named for the number of hours the berry plant requires to wake from dormancy.</p>
                </div>
            </div>
        
            <div class="p-content-row-1">
                <figure class="large-img">
                    <img alt="etaerio logo" src="../assets/images/portfolio/et-variations.webp">
                    <figcaption>The Etaerio brand logo with variations</figcaption>
                </figure>
            </div>

            <div class="p-content-row-1">
                <figure class="large-img">
                    <img alt="etaerio berry emblems" src="../assets/images/portfolio/et-berry-emblems.webp">
                    <figcaption>Each product series has a corresponding emblem</figcaption>
                </figure>
            </div>

            <div class="p-content-row-1">
                <figure class="large-img">
                    <img alt="etaerio product photo" src="../assets/images/portfolio/et-glamour.webp">
                    <figcaption>3D modelling was used to bring these products to life</figcaption>
                </figure>
            </div>

            <div class="p-content-row-2">
                <div>
                    <figure class="medium-img">
                        <img alt="etaerio raspberry presentation" src="../assets/images/portfolio/et-present.webp">
                    </figure>
                </div>
                <div>
                    <figure class="medium-img">
                        <img alt="etaerio 700 series close up" src="../assets/images/portfolio/et-700.webp">
                    </figure>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

export default {
    name: "PortfolioEtaerio",

}
</script>

<style lang="scss" scoped>

.portfolio-cont {
    background-repeat: no-repeat;
    background-size: 75%;

    @include respond-to('small') {
        background-size: 100%;
    }
}

</style>